import moment from 'moment-timezone'
import _ from 'lodash'

import type { DetectionData } from 'app/MissionControlMethaneSolution/types/detection'
import type { UtcISOString } from 'types/datetime'

// constants
import { EMISSION_OBSERVATION_ATTRIBUTION_TYPES } from 'app/MissionControlMethaneSolution/constants/emissionObservation'
import { roundToDecimalPlaces } from './common'

export const getAttributionName = ({
  sourceAttribution,
  site,
  equipmentDescription,
}: Pick<DetectionData, 'sourceAttribution' | 'site'> & {
  equipmentDescription?: string | null
}): string | null | undefined => {
  if (!sourceAttribution) return ''

  const isAttributedToEquipment =
    sourceAttribution.attributionType ===
    EMISSION_OBSERVATION_ATTRIBUTION_TYPES.EQUIPMENT

  return isAttributedToEquipment ? equipmentDescription : site.name
}

export const getEmissionsRateFromTotalVolume = (
  data: {
    endTime: UtcISOString
    startTime: UtcISOString
    totalVolume: number
  }[],
  start: UtcISOString,
  end: UtcISOString
): number | null => {
  if (_.isEmpty(data) || !start || !end) return null

  const totalVolumeSum = _.sumBy(data, 'totalVolume')
  const diffHours = moment(end).diff(moment(start), 'hours', true)
  if (diffHours === 0) return null

  return roundToDecimalPlaces(totalVolumeSum / diffHours, 4)
}
