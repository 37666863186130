import _ from 'lodash'
import { useMemo } from 'react'
import { DETECTION_TYPES_TO_OMIT_ON_MAP } from 'app/MissionControlMethaneSolution/constants/emissionObservation'
import type { DetectionData } from 'app/MissionControlMethaneSolution/types/detection'
import type { GeojsonData } from 'types/map'

const useDetectionsGeoJsonFeatures = (detections?: DetectionData[]) =>
  useMemo(() => {
    return _.reduce(
      detections,
      (acc, { geoJsonFeature, detectionType }) => {
        return !geoJsonFeature ||
          // For now, omit certain detection types since they usually have incorrect coordinates
          DETECTION_TYPES_TO_OMIT_ON_MAP.includes(detectionType)
          ? acc
          : [...acc, geoJsonFeature]
      },
      [] as GeojsonData[]
    )
  }, [detections])

export default useDetectionsGeoJsonFeatures
