// libraries
import _ from 'lodash'
import { point as turfPoint } from '@turf/helpers'

// constants
import { USER_ESSENTIAL_FIELDS } from 'services/api/user'
import {
  CORRELATE_EVENT_TYPES,
  PROGRESS_MAPPING,
  RESOLUTION_MODE_MAPPING,
  WORK_ITEM_LABELS_MAPPING,
} from 'app/MissionControlMethaneSolution/constants/detection'
import { DETECTION_TYPES } from 'app/MissionControlMethaneSolution/constants/common'
import { EMISSION_OBSERVATION_ATTRIBUTION_TYPES } from 'app/MissionControlMethaneSolution/constants/emissionObservation'

// utils
import { getFields, getInlineFragments, getQueryFields } from 'helpers/graphql'
import {
  getEntitiesQuery,
  getEntityGraphql,
  getEntityQuery,
  listEntitiesGraphql,
  mutateEntity,
  MutateEntity,
} from 'services/api/utils'
import { getDetectionTypeLabel } from 'app/MissionControlMethaneSolution/helpers/detection'
import {
  getAttributionName,
  getEmissionsRateFromTotalVolume,
} from 'app/MissionControlMethaneSolution/helpers/emissionObservation'
import { getFriendlyDateTimeDuration } from 'helpers/datetime'

import type { Payload } from 'types/common'
import type {
  EmissionObservation,
  VentingEvent,
} from 'app/MissionControlMethaneSolution/types/graphql'
import type { EmissionObservationData } from 'app/MissionControlMethaneSolution/types/detection'
import type { QueryParams, SelectedFields } from 'types/services'
import { deserializeSite } from './detection'

export const DOMAIN = 'emissionObservation'
const queryDomain = `${DOMAIN}s`

export const EQUIPMENT_REFERENCE_FIELDS = {
  equipmentId: true,
  equipment: {
    id: true,
    name: true,
    description: true,
    components: {
      equipmentComponentName: true,
    },
  },
}

export const VENTING_EVENT_FIELDS_MAPPING = {
  VentingEvent: {
    ventingEventType: true,
    description: true,
    blowdown: true,
    ongoingStatus: true,
    additionalData: true,
  },
}

const clueFields = {
  data: true,
  id: true,
  images: {
    label: true,
    url: true,
  },
  type: true,
}

export const SOURCE_ATTRIBUTION_FIELDS = {
  attributionExplanation: true,
  attributionType: true,
}

const DETECTION_FIELDS_MAPPING = {
  Detection: {
    status: true,
    screeningId: true,
    detectionType: true,
    detectionSource: true,
    sourceDescription: true,
    additionalData: true,
    purpose: true,
    media: {
      downloadUrl: true,
      mediaKey: true,
    },
    component: {
      equipmentComponentName: true,
    },
    resolution: {
      correlatedEmissionObservation: {
        emissionObservation: {
          shortId: true,
        },
      },
      correlatedClue: {
        clue: _.pick(clueFields, ['id', 'data', 'type']),
      },
      resolutionMode: true,
      explanation: true,
    },
    clues: {
      clueGroups: {
        clues: {
          clue: clueFields,
        },
        type: true,
      },
    },
  },
}

export const EMISSION_OBSERVATION_FIELDS = {
  acknowledged: true,
  audit: {
    createdBy: {
      user: USER_ESSENTIAL_FIELDS,
    },
    createdTime: true,
    updatedTime: true,
  },
  complianceDeadline: true,
  complianceSeverity: true,
  correlatedEmissionObservationCount: true,
  closed: true,
  detectedAt: true,
  endTime: true,
  id: true,
  shortId: true,
  startTime: true,
  emissionsRate: {
    value: true,
  },
  equipmentExplanation: true,
  equipmentReference: EQUIPMENT_REFERENCE_FIELDS,
  inputType: true,
  priority: true,
  sourceAttribution: SOURCE_ATTRIBUTION_FIELDS,
  siteReference: {
    site: {
      id: true,
      properties: {
        name: true,
      },
      outlier: true,
      outlierExplanation: true,
      deepDiveProperties: {
        underDeepDive: true,
      },
      observations: true,
    },
  },
  lastComplianceActionAt: true,
  sourceLatitude: true,
  sourceLongitude: true,
  ...getInlineFragments({
    ...DETECTION_FIELDS_MAPPING,
    ...VENTING_EVENT_FIELDS_MAPPING,
  }),
  wip: true,
}

export const getEmissionObservationFields = getQueryFields(
  EMISSION_OBSERVATION_FIELDS
)

export const COMMON_SITE_FIELDS = getFields(
  ['id', 'properties'],
  'siteReference.site'
)

const COMMON_EMISSION_OBSERVATION_FIELDS = [
  'id',
  'shortId',
  'detectedAt',
  'startTime',
  'endTime',
  'audit.updatedTime',
  'emissionsRate.value',
  'equipmentReference.equipmentId',
  'equipmentReference.equipment.description',
  'screeningId',
  'inputType',
  'detectionType',
  'priority',
  'siteReference.site.properties.name',
  'sourceAttribution',
  'sourceLongitude',
  'sourceLatitude',
  'additionalData',
  '__on[1]',
]

const COMMON_INBOX_EMISSION_OBSERVATION_FIELDS = [
  'acknowledged',
  ...getFields(
    [
      '__typeName',
      'screeningId',
      'detectionType',
      'component.equipmentComponentName',
      'additionalData',
    ],
    '__on[0]'
  ),
  ...COMMON_EMISSION_OBSERVATION_FIELDS,
  ...COMMON_SITE_FIELDS,
]

const COMMON_WIP_DETECTION_FIELDS = [
  'resolution',
  'complianceDeadline',
  'complianceSeverity',
  'lastComplianceActionAt',
  'correlatedEmissionObservationCount',
  'startTime',
  'endTime',
  ...getFields(
    [
      '__typeName',
      'screeningId',
      'detectionType',
      'component.equipmentComponentName',
      'status',
      'resolution.explanation',
    ],
    '__on[0]'
  ),
  ...COMMON_EMISSION_OBSERVATION_FIELDS,
  ...COMMON_SITE_FIELDS,
]

const INBOX_DETECTION_VARIABLES = {
  first: 'Int',
  after: 'String',
  sortBy: 'EmissionObservationSortBy',
  filter: 'InboxDetectionFilter',
}

const getEmissionObservationsQuery = (
  queryName: string,
  variables: Payload<string> = INBOX_DETECTION_VARIABLES
) =>
  getEntitiesQuery<EmissionObservation>({
    queryDomain,
    queryName,
    variables,
    getFieldsFn: getEmissionObservationFields,
  })

const INBOX_QUERY_NAME = 'inbox'

export const DETECTION_DEFAULT_OMIT_FIELDS = [
  '__on[0].clues',
  'siteReference.site.observations',
]

export const deserializeEmissionObservation = (
  emissionObservation: Partial<EmissionObservation & VentingEvent>
): EmissionObservationData | null => {
  if (!emissionObservation) return null

  const {
    siteReference,
    equipmentReference,
    resolution,
    emissionsRate,
    complianceDeadline,
    complianceSeverity,
    audit,
    detectionType,
    component,
    priority,
    sourceLatitude,
    sourceLongitude,
    ventingEventType,
    status,
    sourceAttribution,
    startTime,
    endTime,
    additionalData,
  } = emissionObservation
  const { site } = siteReference ?? {}
  const { equipmentId, equipment } = equipmentReference ?? {}
  const { description: equipmentDescription, components } = equipment ?? {}
  const { explanation, resolutionMode, correlatedDetection, correlatedClue } =
    resolution ?? {}
  const { updatedTime } = audit ?? {}
  const { equipmentComponentName } = component || {}
  const { contributingEmissions = [] } = additionalData || {}

  let emissionObservationType = detectionType as string

  const correlatedDetectionShortId = _.get(
    correlatedDetection,
    'detection.shortId'
  )

  const correlatedToType = correlatedDetection
    ? CORRELATE_EVENT_TYPES.WIP
    : !_.isEmpty(correlatedClue)
    ? CORRELATE_EVENT_TYPES.CLUE
    : undefined

  if (ventingEventType) {
    emissionObservationType =
      DETECTION_TYPES.DETECTION_TYPE_VENTING_FLARING_BLOWDOWN
  }

  const deserializedSite = deserializeSite(site)
  const sourceAttributionName = getAttributionName({
    sourceAttribution,
    site: deserializedSite,
    equipmentDescription,
  })
  const isAttributedToEquipment =
    sourceAttribution?.attributionType ===
    EMISSION_OBSERVATION_ATTRIBUTION_TYPES.EQUIPMENT

  const sourceAttributionId = isAttributedToEquipment
    ? equipmentId
    : deserializedSite.id

  const rateFromContributingEmissions = _.isEmpty(contributingEmissions)
    ? null
    : getEmissionsRateFromTotalVolume(contributingEmissions, startTime, endTime)

  return {
    ...emissionObservation,
    site: deserializedSite,
    emissionsRate: emissionsRate?.value || rateFromContributingEmissions,
    ...(explanation && { explanation }),
    ...(resolutionMode && {
      resolutionMode: _.get(RESOLUTION_MODE_MAPPING, resolutionMode),
    }),
    equipment: equipmentDescription ?? equipmentId,
    sourceAttributionName,
    sourceAttributionId,
    createdTime: audit?.createdTime,
    components,
    ...(equipmentComponentName && { equipmentComponentName }),
    compliance: { complianceSeverity, complianceDeadline },
    lastUpdatedTime: updatedTime,
    correlatedTo: {
      type: correlatedToType,
      correlatedDetectionShortId,
      correlatedClue,
    },
    type: getDetectionTypeLabel(emissionObservationType),
    ...(_.isNumber(sourceLongitude) &&
      _.isNumber(sourceLatitude) && {
        geoJsonFeature: turfPoint([sourceLongitude, sourceLatitude], {
          priority,
        }),
      }),
    workItem: _.get(WORK_ITEM_LABELS_MAPPING, status),
    progress: _.get(PROGRESS_MAPPING, status),
    finding: null,
    duration: getFriendlyDateTimeDuration(startTime, endTime, true),
  }
}

const getEmissionObservationByIdQuery = getEntityQuery({
  queryDomain,
  getFieldsFn: getEmissionObservationFields,
})

export const getEmissionObservationById =
  getEntityGraphql<EmissionObservationData>({
    queryDomain,
    getQueryFn: getEmissionObservationByIdQuery,
    queryDisplayName: 'GetEmissionObservationById',
    postProcessFn: deserializeEmissionObservation,
  })

const getEmissionObservationByIdsQuery = getEntityQuery({
  queryDomain,
  queryName: 'byIds',
  variables: { ids: '[ID!]' },
  getFieldsFn: getEmissionObservationFields,
})

/** Although the query returns a list, using 'getEntityGraphql' here to omit 'edges/node' */
export const getEmissionObservationsByIds = getEntityGraphql<
  EmissionObservationData[]
>({
  queryDomain,
  queryName: 'byIds',
  getQueryFn: getEmissionObservationByIdsQuery,
  queryDisplayName: 'GetEmissionObservationByIds',
  postProcessFn: data => data?.map(deserializeEmissionObservation) || [],
})

const GET_EMISSION_OBSERVATIONS_COMMON_PROPS = {
  queryDomain,
  enableLoadMore: false,
  queryDisplayName: 'GetInboxInProgressDetections',
  defaultOmitFields: DETECTION_DEFAULT_OMIT_FIELDS,
  postProcessFn: deserializeEmissionObservation,
}

export const getInboxOpenEmissionObservations =
  listEntitiesGraphql<EmissionObservation>({
    ...GET_EMISSION_OBSERVATIONS_COMMON_PROPS,
    queryName: INBOX_QUERY_NAME,
    getQueryFn: getEmissionObservationsQuery(INBOX_QUERY_NAME),
    queryDisplayName: 'GetInboxOpenEmissionObservations',
    defaultPickFields: COMMON_INBOX_EMISSION_OBSERVATION_FIELDS,
  })

const WIP_QUERY_NAME = 'workInProgress'

const WIP_DETECTION_VARIABLES = {
  ...INBOX_DETECTION_VARIABLES,
  filter: 'WipDetectionFilter',
}

export const getWIPEmissionObservations =
  listEntitiesGraphql<EmissionObservationData>({
    ...GET_EMISSION_OBSERVATIONS_COMMON_PROPS,
    queryName: WIP_QUERY_NAME,
    getQueryFn: getEmissionObservationsQuery(
      WIP_QUERY_NAME,
      WIP_DETECTION_VARIABLES
    ),
    queryDisplayName: 'GetWIPEmissionObservations',
    defaultPickFields: COMMON_WIP_DETECTION_FIELDS,
  })

const GET_CORRELATED_EMISSION_OBSERVATIONS_QUERY_NAME =
  'byCorrelatedToEmissionObservationId'

export const getCorrelatedEmissionObservations =
  listEntitiesGraphql<EmissionObservationData>({
    queryDomain,
    enableLoadMore: false,
    queryName: GET_CORRELATED_EMISSION_OBSERVATIONS_QUERY_NAME,
    queryDisplayName: 'GetCorrelatedEmissionObservations',
    postProcessFn: deserializeEmissionObservation,
    getQueryFn: getEmissionObservationsQuery(
      GET_CORRELATED_EMISSION_OBSERVATIONS_QUERY_NAME,
      { correlatedToEmissionObservationId: 'ID!' }
    ),
    defaultPickFields: [
      'id',
      'shortId',
      'detectedAt',
      'emissionsRate',
      'priority',
      'sourceLatitude',
      'sourceLongitude',
      ...getFields(['__typeName', 'status'], '__on[0]'),
    ],
  })

const mutateEmissionObservation =
  ({
    pickFields,
    omitFields,
    ...restProps
  }: Omit<MutateEntity, 'queryDomain'> & SelectedFields) =>
  ({
    id,
    fieldsWithArguments,
    ...rest
  }: { id?: string; fieldsWithArguments?: QueryParams } & QueryParams) =>
    mutateEntity<EmissionObservationData>({
      queryDomain,
      responseFields: {
        [DOMAIN]: getEmissionObservationFields({ pickFields, omitFields }),
      },
      responsePath: [DOMAIN],
      withIdentifier: false,
      ignoreError: true,
      postProcessFn: deserializeEmissionObservation,
      ...restProps,
    })(id, rest, fieldsWithArguments) as Promise<{
      data: EmissionObservationData
      error?: string
    }>

const ACKNOWLEDGE_EMISSION_OBSERVATION_PAYLOAD = {
  fnName: 'acknowledgeEmissionObservation',
  variableFormat: 'AcknowledgeEmissionObservationInput!',
  withIdentifier: true,
  identifier: 'emissionObservationId',
  omitFields: [
    'lastComplianceActionAt',
    'complianceSeverity',
    'complianceDeadline',
  ],
}

export const acknowledgeEmissionObservation = mutateEmissionObservation({
  ...ACKNOWLEDGE_EMISSION_OBSERVATION_PAYLOAD,
})

const ATTRIBUTION_COMMON_FIELDS = ['__typeName', 'component']

export const attributeEmissionObservationToEquipment =
  mutateEmissionObservation({
    fnName: 'attributeEmissionObservationToEquipment',
    variableFormat: 'AttributeEmissionObservationToEquipmentInput!',
    pickFields: [
      'sourceAttribution',
      'equipmentExplanation',
      'equipmentReference',
      'audit.updatedTime',
      ...getFields(ATTRIBUTION_COMMON_FIELDS, '__on[0]'),
    ],
  })

export const attributeEmissionObservationToSite = mutateEmissionObservation({
  fnName: 'attributeEmissionObservationToSite',
  variableFormat: 'AttributeEmissionObservationToSiteInput!',
  pickFields: [
    'sourceAttribution',
    'equipmentReference',
    'equipmentExplanation',
    'audit.updatedTime',
    'siteReference.site.id',
    'siteReference.site.properties.name',
    ...getFields(ATTRIBUTION_COMMON_FIELDS, '__on[0]'),
  ],
})

export const requestVfbReport = mutateEmissionObservation({
  fnName: 'markDetectionAsVfbReportRequested',
  responseFields: {
    detection: {
      status: true,
    },
  },
  variableFormat: 'MarkDetectionAsVfbReportRequestedInput!',
})

export const pendDetection = mutateEmissionObservation({
  fnName: 'pendDetection',
  responseFields: {
    detection: {
      status: true,
    },
  },
  variableFormat: 'PendDetectionInput!',
})
